@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap);
body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --white: #f7fafc;
  --primary: #5e72e4;
  --primary-40: #5e72e466;
  --gray: #8898aa;
  --opacity-gray: #8898aa55;
  --black-60: rgba(0, 0, 0, 0.6);
  --table-header-bg: #f6f9fc;
  --table-header-border: #e9ecef;
  --text-gray: #525f7f;
  --danger: #f5365c;
  --border-gray: #dee2e6;
  --text-black: #32325d;
  --warning: #ffc107;
  --pdf-color: #f40f02;
  --csv-color: #217345;
  --success: #4fd69c;
}

.max-content {
  max-width: 1400px;
  width: 100vw;
  margin: 0 auto;
}

